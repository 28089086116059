import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { Button, Card, Form } from "react-bootstrap"

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
})

const backendUrl = process.env.GATSBY_BACKEND_HOST

interface Props {
  onClose: () => any
}

const EarlyAccessSignup = ({ onClose }: Props) => {
  const [requestState, setRequestState] = useState({
    sending: false,
    sent: false,
    success: false,
    error: false,
  })

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setRequestState(prev => ({ ...prev, sending: true }))

      axios
        .post(`${backendUrl}/flapmax/measure-early-access/add-contact`, values)
        .then(() => {
          setRequestState({
            sending: false,
            error: false,
            sent: true,
            success: true,
          })
        })
        .catch(err => {
          console.log(err)

          setRequestState({
            sending: false,
            success: false,
            sent: true,
            error: true,
          })
        })
    },
  })

  return (
    <Form as={Card}>
      <h3 className="text-center mb-2">Measure Early Access</h3>
      <p className="text-center mb-4">
        Join the list and we'll contact you soon.
      </p>
      <Form.Group className="mb-3">
        {formik.touched.email && formik.errors.email && (
          <span style={{ color: "red", display: "block" }}>
            {formik.errors.email}
          </span>
        )}
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Enter email"
          name="email"
          id="email"
        />
      </Form.Group>

      {requestState.error && (
        <span
          className="text-center px-2 my-1"
          style={{ fontSize: "12px", color: "red" }}
        >
          We encountered a problem. Please try again soon or contact us at
          contact@flapmax.com
        </span>
      )}

      <div className="d-flex justify-content-between pt-2">
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        {!requestState.sent && (
          <Button
            variant="dark"
            disabled={requestState.sent || requestState.sending}
            onClick={() => formik.handleSubmit()}
            type="submit"
          >
            Submit
          </Button>
        )}
        {requestState.sent && requestState.success && (
          <Button variant="success" disabled>
            Success
          </Button>
        )}
        {requestState.sent && requestState.error && (
          <Button variant="danger" disabled>
            Error
          </Button>
        )}
      </div>
    </Form>
  )
}

export default EarlyAccessSignup
