import React, { useState } from "react"
import { FaServer } from "react-icons/fa"
import { SiGoogleanalytics } from "react-icons/si"
import { GoFileBinary } from "react-icons/go"
import { MdGroupAdd } from "react-icons/md"
import { Modal } from "react-bootstrap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/forms/contact"
import EarlyAccessSignup from "../../components/forms/earlyaccess"

const chipbrain = require("../../images/chipbrain.png")
const analytics = require("../../images/analytics.png")
const isoservers = require("../../images/isoservers.png")
const robotgroup = require("../../images/robotgroup.png")
const blackbg = require("../../images/blackbg.jpeg")

const CoralImagingPage = () => {
  const [showModal, setShowModal] = useState(false)
  const [earlyAccessDisplay, setEarlyAccessDisplay] = useState(false)

  return (
    <Layout>
      <Modal
        show={earlyAccessDisplay}
        onHide={() => setEarlyAccessDisplay(false)}
      >
        <EarlyAccessSignup onClose={() => setEarlyAccessDisplay(false)} />
      </Modal>
      {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
        <ContactForm onClose={() => setShowModal(false)} />
      </Modal> */}
      <SEO
        title="Measure"
        description="Easy-to-use machine learning analytics platform to improve the efficiency of your models across various hardware architectures."
        lang="en"
        meta={[]}
      />

      {/* Hero Section */}
      <div
        className="hero-wrapper"
        style={{ backgroundImage: `url(${blackbg})` }}
      >
        <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column">
          <h1 className="mb-3">Measure</h1>
          <h3>Analyze your AI model across various hardware architectures.</h3>
        </div>
      </div>

      {/* Content Block */}
      <section>
        <div className="container">
          <div className="row">
            <div className="my-3 col-lg-6 col-md-12">
              <p>
                Measure is an application for data scientists and machine
                learning engineers. It offers the ability to easily deploy your
                model to a variety of environments and gain key information from
                performance analytics. It supports inferencing on both Intel and
                AMD hardware, with built-in optimization capabilities to get the
                most out of your models.
              </p>
            </div>
            <div className="my-3 col-lg-6 col-md-12">
              <div className="card mx-auto">
                <h4 className="mb-3 text-center">
                  Contact us to learn more or book a demo:
                </h4>
                <div className="text-center">
                  <a
                    href="mailto:contact@flapmax.com?subject=Measure%20Inquiries%20/%20Demo%20Booking"
                    className="email-link"
                  >
                    contact@flapmax.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Content Block */}
      <section>
        <div className="container">
          <h2 className="text-center mb-8">Feature Highlights</h2>
          <div className="row">
            <div className="my-3 col-lg-3">
              <div className="d-flex justify-content-center">
                <GoFileBinary size={65} />
              </div>
              <h3 className="my-4 text-center">Bring your own model</h3>
              <p className="text-muted text-center">
                Measure is about helping you improve your model. A core feature
                is model uploading and management. Upload it once and you can
                reuse the model as many times as you need.
              </p>
            </div>
            <div className="my-3 col-lg-3">
              <div className="d-flex justify-content-center">
                <SiGoogleanalytics size={65} />
              </div>
              <h3 className="my-4 text-center">Key analytics</h3>
              <p className="text-muted text-center">
                Key analytics about the performance of your model are easy to
                View and share with Measure. We offer a sleek UI that charts
                important data relationships for you.
              </p>
            </div>
            <div className="my-3 col-lg-3">
              <div className="d-flex justify-content-center">
                <FaServer size={65} />
              </div>
              <h3 className="my-4 text-center">Manage environments</h3>
              <p className="text-muted text-center">
                Measure can extend and link to many different remote
                environments for testing various hardware architectures,
                including Intel and AMD processors.
              </p>
            </div>
            <div className="my-3 col-lg-3">
              <div className="d-flex justify-content-center">
                <MdGroupAdd size={65} />
              </div>
              <h3 className="my-4 text-center">Work with your team</h3>
              <p className="text-muted text-center">
                Working with peers is made simple on Measure. You can easily
                invite members to join your team and manage various projects
                together.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Content Block */}
      <section className="my-8">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="my-3 col-lg-6">
              <h2 className="bold">Bring and easily reuse your own models</h2>
              <p>
                Our platform lets you easily upload and access your models
                whenever you need them. We use a combination of cloud-hosted
                databases and object storage to store and use models of any
                size. When running model optimizations we store both the raw and
                optimized models to preserve the original in our application.
              </p>
            </div>
            <div className="my-3 col-lg-6">
              <div className="w-100">
                <img src={chipbrain} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Content Block */}
      <section className="my-8">
        <div className="container">
          <div className="row">
            <div className="my-3 col-lg-6">
              <div className="w-100">
                <img src={analytics} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="my-3 col-lg-6">
              <h2 className="bold">Gain important insights with analytics</h2>
              <p>
                Gain key insights about your models performance by running
                experiments with Measure. Simply select a model, an environment,
                a provided dataset, and we'll do the rest from there. Once an
                experiment is completed you can view the results in the
                analytics page, where charted data and PDF conversion is
                available for ease of viewing, storing, and sharing.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Content Block */}
      <section className="my-8">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row">
            <div className="my-3 col-lg-6">
              <h2 className="bold">Deploy to many environments</h2>
              <p>
                We wanted to make our platform extensible- so we packaged up
                specialized instances of it to be automatically installed and
                ran on a remote environment of your choice. Measure will
                automatically detect your environment and install a compatible
                version of our platform. From there, you can easily manage and
                run experiments on the environment all from the comfort of our
                app.
              </p>
            </div>
            <div className="my-3 col-lg-6">
              <div className="w-100">
                <img src={isoservers} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Content Block */}
      <section className="my-8">
        <div className="container">
          <div className="row">
            <div className="my-3 col-lg-6">
              <div className="w-100">
                <img src={robotgroup} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="my-3 col-lg-6">
              <h2 className="bold">Collaborate and share with your peers</h2>
              <p>
                Teams allow you to share and manage projects with peers of your
                choice, as well as allocate roles depending on what kind of
                access you want to give members. By sharing projects with
                members of your team you give access to all experiments ran for
                that project. The results of these experiments as well as their
                details are then easily viewable for all.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Hero Section */}
      <section className="bg-gray">
        {/* <div className="w-100 d-flex justify-content-center align-items-center flex-column">
          <h2 className="mb-5">Contact us to learn more or view a demo</h2>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-square white-borders"
              onClick={() => setShowModal(true)}
            >
              Contact us
            </button>
          </div>
        </div> */}
      </section>
    </Layout>
  )
}

export default CoralImagingPage
